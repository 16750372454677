import _ from "lodash";
import * as projectTypes from "@/helpers/projectTypes";
import * as projectAddons from "@/helpers/projectAddons";

const initialState = () => ({
  current_step: 1,
  last_step: 6,
  states: [],
  cities: [],
  building_descriptions: [],
  surrounding_terrains: [],
  project_types: [],
  project_addons: [],
  roof_types: [],
  shipping_profiles: [],
  id: null,
  activity_id: null,
  formdata: {
    location: {
      address: {
        address_1: "",
        address_2: "",
        state: "",
        city: "",
        county: "",
        postal_code: "",
        latitude: null,
        longitude: null,
        country: ""
      }
    },
    project_meta: {
      building_description: {},
      surrounding_terrain: {},
      roof_type: {},
      type_pitch: null,
      roof_slope: null,
      building_width: null,
      building_length: null,
      number_of_stories: null,
      number_of_panels: null,
      roof_type_actual: null,
      full_calcs: {
        array: []
      }
    },
    type: {
      project_types: [],
      project_addons: [],
      wet_stamps: 2,
      shipping_profile: {},
      save_shipping_profile: true,
      set_default_shipping_profile: false,
      pzse_express_agree: null
    },
    details: {
      name: "",
      number: "",
      client_po: "",
      description: ""
    }
  },
  account_balance: {},
  total_cost: "0.00",
  current_balance: "0.00",
  remaining_balance: "0.00",
  project_creation_progress: {},
  shipping_profile_id: null,
  project_documents_misc: {},
  electrical_upload_confirmation_enabled: false,
  calculating: false,
  calculation_delay_in_sec: 1.5,
  possible_cities: null,
  full_project_addr: "",
  has_upload_structural_plans: null,
  project_documents_structural_plans: {},
  misc_queue_completed: false,
  sp_queue_completed: false,
  is_express_disabled: false,
  engineering_values: {}
});

export default {
  namespaced: true,
  state: initialState,
  getters: {
    is_project_done: state => {
      return state.current_step === state.last_step;
    },
    is_formdata_rooftype_value_other: state => {
      return state.formdata.project_meta.roof_type?.name === "Other";
    },
    is_express_selected: state => {
      return _.find(
        state.formdata.type.project_addons,
        item => item.name === "PZSE Express"
      );
    },
    wet_stamp_price: state => {
      const count = state.formdata.type.wet_stamps;
      const wetstamp1to4 = projectAddons.wetStampOneToFour();
      const wetstamp5to8 = projectAddons.wetStampFiveToEight();

      if (_.inRange(count, 5, 9)) {
        return state.project_addons.find(addon => addon.id === wetstamp5to8.id);
      }

      return state.project_addons.find(addon => addon.id === wetstamp1to4.id);
    },
    is_iebc_letter: state => {
      const project_types = _.get(state, "formdata.type.project_types", []);
      const projectType = projectTypes.iebcLetter();

      if (projectType) {
        return (
          project_types.length >= 1 &&
          !!_.find(project_types, { id: projectType.value })
        );
      }

      return false;
    },
    is_full_structural: state => {
      const project_types = _.get(state, "formdata.type.project_types", []);
      const projectType = projectTypes.fullStructural();

      if (projectType) {
        return (
          project_types.length >= 1 &&
          !!_.find(project_types, { id: projectType.value })
        );
      }

      return false;
    },
    is_electrical_stamp: state => {
      const project_types = _.get(state, "formdata.type.project_types", []);
      const projectType = projectTypes.electricalStamp();

      if (projectType) {
        return (
          project_types.length >= 1 &&
          !!_.find(project_types, { id: projectType.value })
        );
      }

      return false;
    },
    is_electrical_fault_study: state => {
      const project_types = _.get(state, "formdata.type.project_types", []);
      const projectType = projectTypes.electricalFaultStudy();

      if (projectType) {
        return (
          project_types.length >= 1 &&
          !!_.find(project_types, { id: projectType.value })
        );
      }

      return false;
    },
    is_full_structural_only: state => {
      const project_types = _.get(state, "formdata.type.project_types", []);
      const projectType = projectTypes.fullStructural();

      if (projectType) {
        return (
          project_types.length == 1 &&
          !!_.find(project_types, { id: projectType.value })
        );
      }

      return false;
    }
  },
  mutations: {
    SET_CURRENT_STEP: (state, payload) => (state.current_step = payload),

    // Set predefined values.
    SET_STATES: (state, payload) => (state.states = payload),
    SET_CITIES: (state, payload) => (state.cities = payload),
    SET_BUILDING_DESCRIPTIONS: (state, { building_descriptions }) =>
      (state.building_descriptions = building_descriptions),
    SET_SURROUNDING_TERRAINS: (state, { surrounding_terrains }) =>
      (state.surrounding_terrains = surrounding_terrains),
    SET_PROJECT_TYPES: (state, { project_types }) =>
      (state.project_types = project_types),
    SET_PROJECT_ADDONS: (state, { project_addons }) =>
      (state.project_addons = project_addons),
    SET_ROOF_TYPES: (state, { roof_types }) => (state.roof_types = roof_types),
    SET_SHIPPING_PROFILES: (state, { shipping_profiles }) =>
      (state.shipping_profiles = shipping_profiles),

    // Set project relevant IDs.
    SET_ID: (state, payload) => (state.id = payload),
    SET_ACTIVITY_ID: (state, payload) => (state.activity_id = payload),

    // Set formdata values.
    SET_FORMDATA_LOCATION: (state, payload) =>
      (state.formdata.location = payload),

    SET_FORMDATA_PROJECT_META: (state, payload) =>
      (state.formdata.project_meta = payload),

    SET_FORMDATA_BUILDING_DESCRIPTION: (state, payload) =>
      (state.formdata.project_meta.building_description = payload),

    SET_FORMDATA_SURROUNDING_TERRAIN: (state, payload) =>
      (state.formdata.project_meta.surrounding_terrain = payload),

    SET_FORMDATA_ROOF_TYPE: (state, payload) =>
      (state.formdata.project_meta.roof_type = payload),

    SET_FORMDATA_ROOF_SLOPE: (state, payload) =>
      (state.formdata.project_meta.roof_slope = payload),

    SET_FORMDATA_TYPE_PITCH: (state, payload) =>
      (state.formdata.project_meta.type_pitch = payload),

    SET_FORMDATA_TYPE_PROJECT_TYPES: (state, { project_types }) =>
      (state.formdata.type.project_types = _.orderBy(
        project_types,
        ["name"],
        ["asc"]
      )),
    SET_FORMDATA_TYPE_PROJECT_ADDONS: (state, { project_addons }) =>
      (state.formdata.type.project_addons = _.orderBy(
        project_addons,
        ["name"],
        ["asc"]
      )),
    SET_FORMDATA_TYPE_SHIPPING_PROFILE: (state, { shipping_profile }) =>
      (state.formdata.type.shipping_profile = shipping_profile),
    SET_FORMDATA_TYPE_PZSE_EXPRESS_AGREE: (state, { pzse_express_agree }) =>
      (state.formdata.type.pzse_express_agree = pzse_express_agree),
    SET_FORMDATA_TYPE_SAVE_SHIPPING_PROFILE: (
      state,
      { save_shipping_profile }
    ) => (state.formdata.type.save_shipping_profile = save_shipping_profile),
    SET_FORMDATA_TYPE_SET_DEFAULT_SHIPPING_PROFILE: (
      state,
      { set_default_shipping_profile }
    ) =>
      (state.formdata.type.set_default_shipping_profile = set_default_shipping_profile),
    SET_FORMDATA_TYPE_WET_STAMPS: (state, { wet_stamps }) =>
      (state.formdata.type.wet_stamps = wet_stamps),
    SET_FORMDATA_DETAILS: (state, payload) =>
      (state.formdata.details = payload),

    // Set balance values.
    SET_ACCOUNT_BALANCE: (state, payload) => (state.account_balance = payload),

    // Set total cost.
    SET_TOTAL_COST: (state, payload) => (state.total_cost = payload),

    // Set current balance.
    SET_CURRENT_BALANCE: (state, payload) => (state.current_balance = payload),

    // Set remaining balance.
    SET_REMAINING_BALANCE: (state, payload) =>
      (state.remaining_balance = payload),

    // Set project creation progress.
    SET_PROJECT_CREATION_PROGRESS: (state, payload) =>
      (state.project_creation_progress = payload),

    SET_PROJECT_CREATION_PROGRESS_DETAILS: (state, payload) =>
      (state.project_creation_progress.details = payload),

    SET_SHIPPING_PROFILE_ID: (state, payload) =>
      (state.shipping_profile_id = payload),

    SET_PROJECT_DOCUMENTS_SP: (state, payload) =>
      (state.project_documents_structural_plans = payload),

    SET_PROJECT_DOCUMENTS_MISC: (state, payload) =>
      (state.project_documents_misc = payload),

    SET_HAS_UPLOAD_STRUCTURAL_PLANS: (state, payload) =>
      (state.has_upload_structural_plans = payload),

    SET_MISC_QUEUE_COMPLETED: (state, payload) =>
      (state.misc_queue_completed = payload),

    SET_SP_QUEUE_COMPLETED: (state, payload) =>
      (state.sp_queue_completed = payload),

    SET_INITIAL_STATE: state => Object.assign(state, initialState()),

    SET_FORMDATA: (state, payload) => (state.formdata = payload),

    SET_CALCULATING: (state, payload) => (state.calculating = payload),
    SET_POSSIBLE_CITIES: (state, payload) => (state.possible_cities = payload),
    SET_FULL_ADDR: (state, payload) => (state.full_project_addr = payload),

    SET_FORMDATA_ROOF_TYPE_ACTUAL: (state, payload) =>
      (state.formdata.project_meta.roof_type_actual = payload),

    SET_FORMDATA_BUILDING_WIDTH: (state, payload) =>
      (state.formdata.project_meta.building_width = payload),

    SET_FORMDATA_BUILDING_LENGTH: (state, payload) =>
      (state.formdata.project_meta.building_length = payload),

    SET_FORMDATA_NUMBER_OF_STORIES: (state, payload) =>
      (state.formdata.project_meta.number_of_stories = payload),

    SET_FORMDATA_NUMBER_OF_PANELS: (state, payload) =>
      (state.formdata.project_meta.number_of_panels = payload),

    SET_FORMDATA_FULL_CALCS: (state, payload) =>
      (state.formdata.project_meta.full_calcs = payload),

    SET_IS_EXPRESS_DISABLED: (state, payload) =>
      (state.is_express_disabled = payload),

    SET_ENGINEERING_VALUES: (state, payload) =>
      (state.engineering_values = payload)
  },
  actions: {
    setFormdataLocationAddress: async ({ commit, state }, payload) => {
      // CHECKING OF CITY before changing address
      const possible_cities = state.possible_cities;
      const possible_city_value = [
        "locality",
        "sublocality",
        "neighborhood",
        "administrative_area_level_3"
      ];

      let available_city = "";

      // whatever is available in the dropdown is the correct city
      possible_city_value.some(prop => {
        let possible_city = _.get(possible_cities, prop, null);

        if (possible_city) available_city = possible_city;

        return possible_city;
      });

      payload.address.city = available_city;

      commit("SET_FORMDATA_LOCATION", payload);
    }
  }
};
